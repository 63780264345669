<template>
    <div class="blind-detail">
        <div class="blind-imgs">
            <div @click="$stackRouter.pop()" class="left">
                <i class="material-icons">chevron_left</i>
            </div>
            <div class="blind-img">
                <i class="material-icons">image_not_supported</i>
                <div v-html="$translate(title)" />
            </div>
        </div>
        <div v-if="user" class="blind-profiles">
            <div class="summary-badges">
                <BlindVerifications :user="user" :nickname="nickname" :from="from" />
            </div>
            <div class="profile" v-if="this.from !== 'todaymeet'">
                <BlindBasicProfile :profile="user.profile" :from="from" />
            </div>
        </div>
    </div>
</template>

<script>
import BlindBasicProfile from '@/routes/user-detail/components/BlindBasicProfile'
import BlindVerifications from '@/routes/user-detail/components/BlindVerifications'

export default {
    name: 'BlindUserDetailPage',
    props: {
        userId: Number,
        nickname: String,
        from: String,
    },
    components: {
        BlindBasicProfile,
        BlindVerifications,
    },
    data: () => ({
        user: null,
    }),
    created() {
        this.init()
    },
    computed: {
        title() {
            if (this.from === 'todaymeet') return 'TODAYMEET_BLIND_PROFILE'
            else if (this.from === 'realfriendmeet' || this.from === 'ChatsPage') return 'REALFRIEND_BLIND_PROFILE'
            else return 'SOCIAL_BLIND_PROFILE'
        },
    },
    methods: {
        async init() {
            if (!this.userId) {
                this.$stackRouter.pop()
                return
            }

            this.$loading(true)
            try {
                this.user = await this.$store.dispatch('loadUserDetail', {
                    userId: this.userId,
                    agentId: null,
                })
            } catch (e) {
                this.$toast.error(e.data)
                this.$stackRouter.pop()
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.blind-detail {
    .blind-imgs {
        width: 100%;
        height: 100vw;
        background-color: $grey-02;
        display: flex;
        justify-content: center;
        align-items: center;

        .left {
            padding: 20px 0 0 24px;
            position: fixed;
            top: 0;
            left: 0;

            i {
                font-weight: bolder;
            }
        }

        .blind-img {
            @include flex;
            @include center;

            i {
                font-size: 40px;
                margin-bottom: 16px;
            }
            p {
                font-size: 14px;
                @include f-bold;
            }
        }
    }

    .blind-profiles {
        .profile {
            margin-top: 32px;
        }
    }
}
</style>
