<template>
    <div>
        <div v-if="user" class="nickname-container">
            <span class="nickname m-b-8" v-html="parsedNick" />
            <div v-if="this.from !== 'realfriendmeet'" class="gender-age">
                <span>{{ user.gender === 0 ? '남성' : '여성' }}, </span>
                <span>{{ $options.filters.blindAge(howOld) }}</span>
            </div>
        </div>
        <template v-if="verificationBadgesAll.length">
            <VerificationBadges :verificationBadges="verificationBadgesAll" />
        </template>
    </div>
</template>

<script>
import VerificationBadges from '@/routes/user-detail/components/VerificationBadges'

export default {
    name: 'BlindVerifications',
    props: {
        nickname: String,
        user: Object,
        from: String,
    },
    components: {
        VerificationBadges,
    },
    computed: {
        parsedNick() {
            if (this.from === 'realfriendmeet') return `${this.nickname} <span class="f-16 f-regular">(주선자)</span>`
            if (this.from === 'ChatsPage') return this.user.name
            else return this.nickname
        },
        verificationBadgesAll() {
            const badges = ((this.user || {}).verification_badges || []).filter(
                ({ confirmed, show_profile: show, display_method: dMethod }) => {
                    if (this.from === 'todaymeet' || this.from === 'realfriendmeet') {
                        return confirmed && show && dMethod !== 'photo'
                    } else return confirmed && show
                },
            )
            badges.sort((badgeA, badgeB) => {
                if (!(badgeA.display_method || '').includes('photo') && (badgeB.display_method || '').includes('photo'))
                    return -1

                if (badgeA.category === badgeB.category) return badgeA.position - badgeB.position
            })

            return badges
        },
        howOld() {
            return this.$options.filters.asAge(this.user.profile.birthday)
        },
    },
}
</script>

<style scoped lang="scss">
.nickname-container {
    padding: 28px 16px 0 16px;

    .nickname {
        font-size: 20px;
        font-weight: 500;
        color: black;

        @include f-medium;
    }

    .aside {
        font-size: 16px;
    }

    .gender-age {
        font-size: 16px;
        margin-top: 8px;
    }
}

.v-badge {
    padding: 0 16px;
}

.grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    gap: 8px;

    .cert-badge {
        width: 84px;
        height: 28px;
        border-radius: 14px;
        border: solid 1px $grey-03;

        @include center;
        i {
            font-size: 14px;
            color: $purple-primary;
        }
        span {
            font-size: 12px;
        }
    }
}
</style>
