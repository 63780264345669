<template>
    <div class="blind-basics">
        <div class="title">
            <p id="blind-info">주선자 정보</p>
            <i class="material-icons m-l-5 f-18" @click="isSnackbarOpened = !isSnackbarOpened">info</i>
            <div v-if="isSnackbarOpened" class="snackbar">
                <div v-if="from === 'todaymeet'">
                    <p>호스트의 정보입니다.</p>
                    <p>더 많은 정보는 매칭 성사 후 열람 가능합니다.</p>
                </div>
                <div v-else-if="from === 'realfriendmeet'">
                    <p>주선자의 정보입니다.</p>
                    <p>친구의 정보는 주선자가 채팅을 통해 전달해 드려요!</p>
                </div>
            </div>
        </div>
        <ul class="items">
            <li class="item" v-for="[key, value] in essentials" :key="key">
                <template>
                    <div class="key" v-html="$translate(key)" />
                    <div class="value">
                        <span class="content">{{ value.content }}</span>
                        <span v-if="value.verified" class="verified-badge">
                            <i class="material-icons">check</i>
                            인증완료
                        </span>
                    </div>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BlindBasicProfile',
    props: {
        profile: Object,
        from: String,
    },
    data: () => ({
        essentials: null,
        isSnackbarOpened: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.populateProfile()
        },
        populateProfile() {
            const essentials = new Map()

            essentials.set('JOB_NAME', {
                content: this.profile.job_name,
            })

            let companyString = `${this.profile.job_category.name}`

            if (this.profile.job_type_name) {
                if (this.profile.company_name) {
                    companyString += ` (${this.profile.company_name} - ${this.profile.job_type_name})`
                } else {
                    companyString += ` (${this.profile.job_type_name})`
                }
            }

            essentials.set('COMPANY_NAME', {
                content: companyString,
                verified: this.profile.company_verified,
            })

            let schoolString = ''
            let quote = ''
            // 고등학교 졸업 및 기타의 경우
            if (this.profile.school.id === 5) {
                schoolString = '고등학교 졸업'
            } else {
                schoolString += this.profile.school_type.name // 인서울4년제

                if (this.profile.university_name && this.profile.major_name) {
                    quote = ` (${this.profile.university_name} - ${this.profile.major_name})`
                } else if (this.profile.university_name) {
                    quote = ` (${this.profile.university_name})`
                }

                // 대학원 재학/졸업의 경우
                if (this.profile.school_id === 3 || this.profile.school_id === 4) {
                    schoolString += ` ${this.profile.school.name}`
                } else {
                    if (this.profile.is_student) {
                        schoolString += ' 재학'
                    } else {
                        schoolString += ' 졸업'
                    }
                }
                schoolString += quote
            }

            essentials.set('UNIVERSITY', {
                content: schoolString,
                verified: this.profile.university_verified,
            })
            this.essentials = essentials
        },
    },
}
</script>

<style scoped lang="scss">
.blind-basics {
    padding: 32px 16px 24px 16px;
    //border-top: 1px solid $grey-02;

    .title {
        @include f-medium;
        @include items-center;
        position: relative;
        margin-bottom: 28px;
        color: black;
        font-size: 20px;
        font-weight: 500;
        display: flex;
    }
    .item {
        display: flex;
        margin-bottom: 24px;
    }

    .key {
        font-size: 15px;
        color: $grey-06;
        width: 82px;

        @include flex-wrap;
    }

    .value {
        $content-line-height: 17.25px;
        display: flex;
        line-height: $content-line-height;

        .content {
            color: black;
        }

        .verified-badge {
            display: flex;
            align-self: flex-start;
            align-items: center;
            margin-left: 8px;
            color: $purple-primary;
            font-size: 12px;

            @include flex-wrap;
            @include f-medium;

            .material-icons {
                font-size: 10px;
                border-radius: 50%;
                border: solid 1px $purple-primary;
                margin-right: 2px;
            }
        }
    }
}
.snackbar {
    width: calc(100vw - 32px);
    min-width: calc(100vw - 32px);
    top: 32px;

    .list {
        p {
            margin: 0;
        }

        .item {
            letter-spacing: -0.2px;
            line-height: normal;

            &-title {
                font-size: 15px;
                color: #59defe;
                margin-bottom: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .content {
                padding-left: 22px;

                .paren {
                    color: $grey-05;
                }
            }
        }
    }
}
</style>
